import React from "react";
import logo from "../../../assets/olleh-logo.png";
import "../../../style/header.css";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <section className="olleh-header">
        <div className="olleh-header-wrapper section-spacing">
          <Link to="#">
            <div className="olleh-logo">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <div className="olleh-header-btn">
            <Link to="#" className="btn-primary">
              Get In Touch
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
