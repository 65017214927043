import React, { useRef } from "react";
import Header from "../components/common/header/Header";
import Introduction from "../components/home/introduction/Introduction";
import Waitlist from "../components/home/waitlist/Waitlist";
import CompanyAgent from "../components/home/companyAgent/CompanyAgent";
import CompanyWork from "../components/home/companyWork/CompanyWork";
import Footer from "../components/common/footer/Footer";

const Home = () => {
  const sectionThreeRef = useRef(null);

  const scrollToSection = () => {
    sectionThreeRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Header />
      <Introduction scrollToSection={scrollToSection} />
      <Waitlist ref={sectionThreeRef} />
      <CompanyAgent scrollToSection={scrollToSection} />
      <CompanyWork scrollToSection={scrollToSection} />
      <Footer />
    </>
  );
};

export default Home;
