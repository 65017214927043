import React, { useEffect, useState } from "react";
import "./feedbackPopup.css";
import axios from "axios";

const FeedbackPopup = ({ isOpen, onClose }) => {
  const [star_rating, setRating] = useState(0);
  const [message, setComment] = useState("");

  const handleRatingClick = (rate) => {
    setRating(rate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback/create`,
        { user_id: 1, call_id: 2, star_rating, message },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        }
      );
      console.log("Feedback submitted successfully:", response.data);
      onClose();
    } catch (error) {
      console.error("There was an error submitting the feedback:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="feedback-popup-overlay">
      <div className="feedback-popup">
        <h2>Rate Your Experience</h2>
        <div className="stars">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`star ${star <= star_rating ? "selected" : ""}`}
              onClick={() => handleRatingClick(star)}
            >
              &#9733;
            </span>
          ))}
        </div>
        <form onSubmit={handleSubmit}>
          <textarea
            value={message}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Leave your message here..."
          />
          <button type="submit" className="btn-primary">
            Submit Feedback
          </button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackPopup;
