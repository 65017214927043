// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .feedback-popup {
    background: white;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 600px;
  }
  .feedback-popup button {
    margin-top: 1rem;
  }
  .feedback-popup h2{
    font-size: 40px;
    line-height: 48px;
  }
  .feedback-popup textarea{
    font-size: 18px;
  }
  .stars {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
  }
  
  .star {
    font-size: 2rem;
    cursor: pointer;
    margin: 0 5px;
    color: grey;
  }
  
  .star.selected {
    color: gold;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: white;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/popup/feedbackPopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,eAAe;IACf,aAAa;IACb,WAAW;EACb;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".feedback-popup-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .feedback-popup {\n    background: white;\n    padding: 3rem;\n    border-radius: 10px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    width: 600px;\n  }\n  .feedback-popup button {\n    margin-top: 1rem;\n  }\n  .feedback-popup h2{\n    font-size: 40px;\n    line-height: 48px;\n  }\n  .feedback-popup textarea{\n    font-size: 18px;\n  }\n  .stars {\n    display: flex;\n    justify-content: center;\n    margin: 0.5rem 0 1rem 0;\n  }\n  \n  .star {\n    font-size: 2rem;\n    cursor: pointer;\n    margin: 0 5px;\n    color: grey;\n  }\n  \n  .star.selected {\n    color: gold;\n  }\n  \n  textarea {\n    width: 100%;\n    height: 100px;\n    margin-bottom: 20px;\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n  }\n  \n  button {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    background: #007bff;\n    color: white;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
