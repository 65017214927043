import React, { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import "./microphone.css";
import HeaderLogo from "../../assets/olleh-call-logo.png";
import FeedbackPopup from "../popup/FeedbackPopup";
import axios from "axios";
import { toast } from "react-toastify";
import callSuccessImg from "../../assets/call-start.png";
import callDangerImg from "../../assets/call-stop.png";
import { jwtDecode } from "jwt-decode";

const MicrophoneStream = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [overallCallTime, setOverallCallTime] = useState(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const audioElement = useRef(null);
  const socket = useRef(null);
  const mediaRecorder = useRef(null);
  const intervalId = useRef(null);
  const timerIntervalId = useRef(null);
  const audioQueue = useRef([]); // Queue for audio chunks
  const nextTime = useRef(0); // Scheduling audio playback
  const audioContext = useRef(new AudioContext()); // Web Audio API
  const [tokenVerification, setTokenVerification] = useState("");
  // const localSocket = useRef(null);

  const [timer, setTimer] = useState(0);
  const [token, setToken] = useState("");
  const [staticToken, setStaticToken] = useState(null); // Token for authentication
  const [decodedUserId, setDecodedUserId] = useState(null); // State to store user ID from the decoded token

  // let decodeToken;
  // const userToken = JSON.parse(localStorage.getItem("userToken") || "null");

  // if (userToken) {
  //   decodeToken = jwtDecode(userToken);
  // }

  const checkUserTokenValidity = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify-token`,
        { token, test: true },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        }
      );
    } catch (error) {
      console.error("There was an error verify-token:", error);
    }
  };
  const verifyUser = async () => {
    try {
      const { data } = await axios.post(
        // `${process.env.REACT_APP_USER_VERIFICATION_API}/verify-token`,
        `${process.env.REACT_APP_SOCKET_API_URL}/register_user_session`,

        // { token: token } uncomment while push
        { token: token }
      );
      setTokenVerification(data.status);
    } catch (error) {
      console.error("There was an error submitting the feedback:", error);
    }
  };

  useEffect(() => {
    checkUserTokenValidity();
    verifyUser();
  }, [token]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getToken = urlParams.get("token");
    setToken(getToken);
    const envToken = process.env.REACT_APP_AUTH_TOKEN;
    setStaticToken(envToken);
    if (getToken) {
      try {
        const decodedToken = jwtDecode(getToken);
        const userId = decodedToken?.user?.id; // Get the user ID from the decoded token
        setDecodedUserId(userId); // Save the user ID in state
        console.log("Decoded user ID:", userId);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  useEffect(() => {
    socket.current = io(`${process.env.REACT_APP_SOCKET_API_URL}`);
    // localSocket.current = io(`${process.env.REACT_APP_API_URL}`);

    // Listening for audio chunks
    socket.current.on("audio_chunk", handleAudioChunk);
    socket.current.on("be2fe_stats", saveStatData);
    // be2fe_stats

    return () => {
      socket.current.disconnect();
      // localSocket.current.disconnect();
    };
  }, []);

  const handleAudioChunk = async (data) => {
    try {
      const base64Data = data.data;
      const binaryString = atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "audio/wav" });
      const fileReader = new FileReader();

      fileReader.onload = function () {
        audioContext.current
          .decodeAudioData(fileReader.result)
          .then((buffer) => {
            audioQueue.current.push(buffer);
            playNextAudio(); // Play audio when a new buffer is added
          })
          .catch((error) => {
            console.error("Error decoding audio data:", error);
          });
      };

      fileReader.readAsArrayBuffer(blob);
    } catch (e) {
      console.error("Error processing audio chunk:", e);
    }
  };

  const getFormattedUTCTime = () => {
    const now = new Date();
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");
    const seconds = String(now.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(now.getUTCMilliseconds()).padStart(3, "0");

    return `${minutes}:${seconds} ${milliseconds}`;
  };

  function parseTime(timeStr) {
    let [time, ms] = timeStr.split(" ");
    let [minutes, seconds] = time.split(":").map(Number);
    ms = Number(ms);
    return { minutes, seconds, ms };
  }

  function timeToMilliseconds(timeObj) {
    return (timeObj.minutes * 60 + timeObj.seconds) * 1000 + timeObj.ms;
  }

  const saveStatData = async (data) => {
    let start_time = data.stats.frontend_time;
    let end_time = getFormattedUTCTime();

    let startTimeObj = parseTime(start_time);
    let endTimeObj = parseTime(end_time);

    let startTimeMs = timeToMilliseconds(startTimeObj);
    let endTimeMs = timeToMilliseconds(endTimeObj);

    let timeDifferenceMs = endTimeMs - startTimeMs;

    console.log("decodedUserId in saveStatData", decodedUserId);
    const logData = {
      user_id: decodedUserId,
      call_id: "12345",
      frontend_time: data.stats.frontend_time,
      backend_time: data.stats.backend_time,
      time_for_stt: data.stats.time_for_STT,
      time_for_rag: data.stats.time_for_RAG,
      time_for_tts: data.stats.time_for_TTS,
      get_formatted_utc_time: getFormattedUTCTime(),
      length: data.stats.length,
      text: data.stats.text,
      rag: data.stats.RAG,
      is_agent_speaking: data.stats.is_agent_speaking,
      request_counter: data.stats.request_counter,
      vap_results: data.stats.vap_results,
      thread_time: data.stats.thread_time,
      time_differ: timeDifferenceMs,
      end_time: getFormattedUTCTime(),
    };

    console.log("Speaking::", logData?.is_user_speaking);

    // Play/pause logic based on is_user_speaking (commented in original code)
    // if (logData?.is_user_speaking) {
    //     if (audioElement?.current?.readyState >= 2) {
    //         audioElement.current.pause();
    //     }
    // } else {
    //     if (audioElement?.current?.readyState >= 2) {
    //         audioElement.current.play();
    //     }
    // }

    // localSocket.current.emit("save_log", logData);
  };

  const playNextAudio = async () => {
    if (audioContext.current.state === "suspended") {
      await audioContext.current.resume();
    }

    while (audioQueue.current.length > 0) {
      const buffer = audioQueue.current.shift();
      const sourceNode = audioContext.current.createBufferSource();
      sourceNode.buffer = buffer;
      sourceNode.connect(audioContext.current.destination);

      const now = audioContext.current.currentTime;
      if (nextTime.current < now) {
        nextTime.current = now;
      }

      sourceNode.start(nextTime.current);
      nextTime.current += buffer.duration;
    }
  };

  const startRecording = () => {
    if (
      (!tokenVerification && socket.current.disconnected) 
      // ||
      // localSocket.current.disconnected
    ) {
      toast.error("Failed to connect to the server.");
    } else {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error("getUserMedia is not supported in this browser.");
        toast.error("Your browser does not support audio recording.");
        return;
      }
      setIsRecording(true);
      setTimer(0);
      setOverallCallTime(null);
      timerIntervalId.current = setInterval(
        () => setTimer((prevTimer) => prevTimer + 1),
        1000
      );

      socket.current.emit("start_call", { token: token });
      socket.current.emit("start_stream", { token: token });
      // localSocket.current.emit("start_recording");
      if (!window.MediaRecorder) {
        console.error("MediaRecorder is not supported on this browser");
      }else{
        console.log("MediaRecorder is supported on this browser");
      }

      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        console.log('mediaRecorder.current', mediaRecorder.current)
        // console.log('mediaRecorder', mediaRecorder)
        console.log('mediaRecorder.current.start before')
        mediaRecorder.current.start(1000);
        console.log('mediaRecorder.current.start after')
        intervalId.current = setInterval(
          () => mediaRecorder.current.requestData(),
          1000
        );
        console.log('mediaRecorder.current.start last')

        mediaRecorder.current.addEventListener("dataavailable", (event) => {
          let request_counter = 0;
          if (event?.data?.size > 0) {
            const audioBlob = new Blob([event?.data], { type: "audio/wav" });
            console.log('audioBlob', audioBlob)
            request_counter += 1;
            socket.current.emit("fe2be_call_data", {
              blob: audioBlob,
              stats: {
                time: getFormattedUTCTime(),
                length: event?.data?.size,
                text: "Audio chunk from client",
                request_counter: request_counter,
                token: token,
              },
            });
          }
        });
        // socket.current.emit("start_recording", { token: token });
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      clearInterval(intervalId.current);
      clearInterval(timerIntervalId.current);
      setIsRecording(false);
      setOverallCallTime(timer);
      socket.current.emit("stop_call", { token: token });
      // localSocket.current.emit("stop_recording");
    }
  };

  const handleButtonClick = () => {
    isRecording ? stopRecording() : startRecording();
  };

  return (
    <section className="microphone-main-continer">
      <div className="microphone-container">
        <div className="microphone-inner-container section-spacing">
          <div className="logo-content">
            <img src={HeaderLogo} alt="logo" />
          </div>
          <div className="audio-btns">
            <audio id="assistant_audio" ref={audioElement}></audio>
            <div className="call-img" onClick={handleButtonClick}>
              {isRecording ? (
                <img src={callDangerImg} alt="callDangerImage" />
              ) : (
                <img src={callSuccessImg} alt="callSuccessImage" />
              )}
            </div>
            <div
              className={
                isRecording
                  ? "stop-call call-btn-text"
                  : "start-call call-btn-text"
              }
            >
              {isRecording ? "Stop Call" : "Start Call"}
            </div>
            {isRecording && (
              <p className="call-content">
                Call Time: {Math.floor(timer / 60)}:{timer % 60}
              </p>
            )}
            {!isRecording && overallCallTime !== null && (
              <p className="call-content">
                Overall Call Time: {Math.floor(overallCallTime / 60)}:
                {overallCallTime % 60}
              </p>
            )}
          </div>
        </div>
        <FeedbackPopup
          isOpen={showFeedbackPopup}
          onClose={() => setShowFeedbackPopup(false)}
        />
      </div>
    </section>
  );
};

export default MicrophoneStream;
